<template>
  <div class="min-h-screen flex justify-center items-center">
    <div class="container max-w-2xl">
      <div class="h-[calc(100vh_-_138px)] p-4">
        <slot />
      </div>
      <div class="flex flex-col gap-4 px-4 pt-4">
        <div class="flex justify-center">
          <div class="flex flex-col p-2 gap-1">
            <p class="text-[#A2A2A2] text-[8px]">
              Hubungi hotline kami di <span class="text-[#FFBC00]">1500605</span>
            </p>
            <div class="flex gap-2 justify-center">
              <img src="/images/terdaftar-dan-diawasi.svg" alt="Terdafdaftar dan diawasi">
              <img src="/images/ojk-logo.svg" alt="Otoritas Jasa Keuangan">
            </div>
          </div>
        </div>
        <div class="flex justify-center items-center gap-2 pb-6">
          <img src="/images/kp-logo-colored.svg" alt="Kredit Plus Logo">
          <img src="/images/sally-logo-colored.svg" alt="Sally Logo" class="h-[24px]">
        </div>
      </div>
    </div>
  </div>
</template>
